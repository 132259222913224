<template>
  <div v-if="sidebarOpen" class="modal-global">
    <div class="loading" v-if="loading"></div>
    <div class="modal-container" v-if="!loading" :class="{'dark-bg-primary': theme === 'dark'}">

        <div class="modal-header">
          <div style="display: flex; align-items: center; gap: 4px;">
            <div class="status-circle" :class="{'status-circle_negative': deadlineExpired(task.deadline) }"></div>
            <span :class="{'dark-text': theme === 'dark'}">{{ task.title }}</span>
          </div>
          <!-- <span :class="{'dark-text': theme === 'dark'}">{{ task.title }}</span> -->
          <button @click="closeSidebar" :class="{'dark-text': theme === 'dark'}">×</button>
        </div>
        <div class="modal-body" >
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Статус</p>
            <div class="modal-input-wrapper-span modal-input-wrapper-span_start" :class="{'dark-input': theme === 'dark'}">
              <p :class="{'dark-text': theme === 'dark'}">{{ task.successful === false ? 'Отклонено' : getStatus(task.status) }}</p>
            </div>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Описание</p>
            <input v-model="selectedText" :class="{'dark-input': theme === 'dark'}"/>
          </div>

          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Тип задачи</p>
            <select v-model="selectedTypeID" required :class="{'dark-input': theme === 'dark'}">
              <option v-for="type in typeList" :key="type.id" :value="type.id">{{ type.title }}</option>
            </select>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Приоритет задачи</p>
            <select v-model="selectedPriority" :class="{'dark-input': theme === 'dark'}">
              <option value="very_high">Очень высокий</option>
              <option value="high">Высокий</option>
              <option value="average">Средний</option>
              <option value="low">Низкий</option>
              <option value="very_low">Очень низкий</option>
            </select>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Дедлайн</p>
            <input v-model="formattedDeadline" required :class="{'dark-input': theme === 'dark'}"
              @click="visModaldate" v-show="!modalDate" />
            <div v-if="modalDate">
              <DatePicker v-model="deadline" :class="{'dark-datepicker': theme === 'dark'}" transparent borderless mode="dateTime" is24hr></DatePicker>
              <div>
                <button @click="cancelDate">Отмена</button>
                <button @click="visModaldate">Ok</button>
              </div>
            </div>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">checkpoint</p>
            <input v-model="formattedCheckpoint" :class="{'dark-input': theme === 'dark'}"
              @click="visModalCheckpoint" v-show="!modalCheckpoint" />
            <button v-if="checkpoint" @click="resetCheckpoint" :class="{'dark-text': theme === 'dark'}" class="modal-input-wrapper-close">×</button>
            <div v-if="modalCheckpoint">
              <DatePicker v-model="checkpoint" :class="{'dark-datepicker': theme === 'dark'}" transparent borderless mode="dateTime" is24hr></DatePicker>
              <div>
                <button @click="cancelCheckpoint">Отмена</button>
                <button @click="visModalCheckpoint">Ok</button>
              </div>
            </div>
          </div>

          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">checkbox</p>
            <input v-model="formattedCheckbox" :class="{'dark-input': theme === 'dark'}"
              @click="visModalCheckbox" v-show="!modalCheckbox" />
            <button v-if="checkbox" @click="resetCheckbox" :class="{'dark-text': theme === 'dark'}" class="modal-input-wrapper-close">×</button>
            <div v-if="modalCheckbox">
              <DatePicker v-model="checkbox" :class="{'dark-datepicker': theme === 'dark'}" transparent borderless mode="dateTime" is24hr></DatePicker>
              <div>
                <button @click="cancelCheckbox">Отмена</button>
                <button @click="visModalCheckbox">Ok</button>
              </div>
            </div>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Создатель</p>
            <div class="modal-input-wrapper-span modal-input-wrapper-span_start" :class="{'dark-input': theme === 'dark'}">
              <!-- <img :src="getImgUrl(task.creator_photo_url)" width="20" height="20" alt="photo" /> -->
              <p :class="{'dark-text': theme === 'dark'}" style="font-size: 16px;">{{ task.creator_name }} {{ task.creator_surname }}</p>
            </div>
          </div>
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Исполнитель</p>
            <input type="text" v-model="selectedUser" @click="changeShowDropdown" :class="{'dark-input': theme === 'dark'}"
              required />
            <ul v-if="showDropdown" class="user-list" style="top:32px !important; max-height: 200px;  overflow-y: auto;">
              <li v-for="user in filteredOrgUsers" :key="user.id" @click.prevent="changeSelectedUser(user)">
                {{ user.name }} {{ user.surname }} {{ user.patronymic }}
              </li>
            </ul>
          </div>

          <div v-for="minitask in task.mini_tasks" :key="minitask" class="modal-input-wrapper modal-input-wrapper_big">
            <p :class="{'dark-text': theme === 'dark'}">Подзадача</p>
            <div class="modal-input-wrapper-span modal-input-wrapper-span_start" :class="{'dark-input': theme === 'dark'}">
              <p> {{ minitask.title }} </p>
            </div>
          </div>

          <div v-if="spectators.length" class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Наблюдатели</p>
            <div>
              <div v-if="!spectators.length" class="modal-input-wrapper-span" :class="{'dark-input': theme === 'dark'}"></div>
              <div class="modal-input-wrapper-span" v-for="(spectator, index) in spectators" :key="index" :class="{'dark-input': theme === 'dark'}">
                <img :src="getImgUrl(spectator.user_photo_url)" width="20" height="20" alt="photo" />
                <p :class="{'dark-text': theme === 'dark'}">{{ spectator.user_name }} {{ spectator.user_surname }}</p>
              </div>
            </div>
          </div>

          <div v-for="comment, idx in comments" :key="comment.id" class="modal-comment">
            {{ idx + 1 }}. {{ comment.user_surname }} {{ comment.user_name }}: {{ comment.text }}
            <!-- comments -->
          </div>

          <div style="position: relative;">
            <input v-model="commentValue" class="modal-comment" type="text" placeholder="добавить комментарий">
            <img
              src="@/assets/images/send.svg"
              @click="createComment"
              style="width: 20px; position:absolute; top:10px; right: 10px"
              alt="send"
            />
          </div>

          <div style="position: relative;">
            <input v-model="minitaskValue" class="modal-comment" type="text" placeholder="добавить подзадачу">
            <img
              src="@/assets/images/send.svg"
              @click="createMinitask"
              style="width: 20px; position:absolute; top:10px; right: 10px"
              alt="send"
            />
          </div>

        <!-- <div v-if="task.creator_id === createrID" class="add_spectator" @click="showAddspecs" :class="{'dark-text': theme === 'dark'}">
          <p :class="{'dark-text': theme === 'dark'}">Добавить наблюдателя</p>
        </div> -->

        <div class="accepts">
          <!-- <label
            v-if="(['new'].indexOf(task.status) > -1) && (task.executor_id !== executorID) && (task.creator_id !== createrID)"
            class="not_executor">Вы не исполнитель или создатель задачи</label>
          <label v-if="(['new', 'working'].indexOf(task.status) > -1) && (task.executor_id !== executorID)"
            class="not_executor">Вы не исполнитель задачи</label>
          <label v-if="(['new'].indexOf(task.status) > -1) && (task.executor_id === executorID)" class="hours"
            for="hours">Срок выполнения - {{ time }} ч.</label>
          <input v-if="(['new'].indexOf(task.status) > -1) && (task.executor_id === executorID)" type="range" min="0"
            max="24" v-model="time" class="slider" id="hours"> -->

          <button
            v-if="task.creator_id === createrID"
            @click="updateTask()"
            class="start-task-btn"
            :class="{'start-task-btn-dark': theme === 'dark'}"
            style="background-color: #fff"
          >
            Сохранить изменения
          </button>
          <button
            v-if="(['new', 'working'].indexOf(task.status) > -1) && (task.executor_id === executorID)"
            @click="mainResufeTask"
            class="start-task-btn negative"
            :class="{'negative-dark': theme === 'dark'}"
            :negativebtn="negativebtn"
          >
            Отклонить
          </button>
          <button
            v-if="(['new'].indexOf(task.status) > -1) && (task.executor_id === executorID)"
            @click="startTask(id, time)"
            class="start-task-btn"
            :class="{'start-task-btn-dark': theme === 'dark'}"
          >
            Принять задачу
          </button>
          <p v-if="task.is_started">Задание в работе ({{ task.start_date }})</p>
          <button
            v-if="(['working'].indexOf(task.status) > -1) && (task.executor_id === executorID)"
            @click="completeTask(id)"
            class="complete-task-btn"
            :class="{'complete-task-btn-dark': theme === 'dark'}"
          >
            Завершить задачу
          </button>
          <button
            v-if="(['completed'].indexOf(task.status) > -1)"
            @click="acceptTaskEnding(id)"
            class="complete-task-btn"
            :class="{'complete-task-btn-dark': theme === 'dark'}"
          >
            Подтвердить завершение
          </button>
        </div>
      </div>

    </div>
    <transition-group name="error">
      <div class="error_modal" v-if="error">
        <div class="error_modal-content">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
/* eslint-disable */
import { Header } from '@/api/index'
// import DelegateTaskComp from '@/components/WorkSpace/DelegateTaskComp.vue'
// import AddSpectatorComp from '@/components/WorkSpace/AddSpectatorComp.vue'
// import TaskCommentsComp from '@/components/WorkSpace/TaskCommentsComp.vue'
import VueCookies from 'vue-cookies'
import { DatePicker } from 'v-calendar'
import { cancelTask, startTask, updateTask } from '@/api/patch'
import { createTaskComment, createMinitask } from '@/api/post'
import axios from 'axios'
import { getOrganizationMember, getOrganizationsUsers, getUserById, getTaskComments, getUserActive } from '@/api/get'
import getImgUrl from '@/mixins/getImgUrl.vue'
export default {
  mixins: [getImgUrl],
  data () {
    return {
      sidebarOpen: true,
      loading: true,
      editMode: false,
      editedText: null,
      show_change_window: false,
      show_comments_window: false,
      show_addspec_window: false,
      negativebtn: false,
      error: false,
      errorMessage: '',
      time: 2,
      createrID: this.$cookies.get('ID'),
      executorID: this.$cookies.get('ID'),
      userId: this.$cookies.get('ID'),
      theme: VueCookies.get('THEME'),
      organizationId: '',
      id: this.data.id,
      task: [],
      minitasks: [],
      spectators: [],
      modal_negative: false,
      comments: [],
      // voiseMsgResponse: false,
      // mediaRecorder: null,
      // mediaStream: null,
      // transcript: null,
      audioChunks: [],
      contextMenuX: 0,
      contextMenuY: 0,
      textMessage: null,
      messages: [],
      image: null,
      audioBlob: null,
      audioBlobBase64: null,
      text: '',
      label: '',
      typeList: [],
      selectedText: '',
      selectedPriority: '',
      selectedTypeID: '',
      modalDate: false,
      modalCheckpoint: false,
      modalCheckbox: false,
      deadline: '',
      checkpoint: '',
      checkbox: '',
      showDropdown: false,
      orgUsers: [],
      selectedUser: '',
      selectedUserId: '',
      // newComment: ''
      textValue: '',
      comments: [],
      commentValue: '',
      minitaskValue: '',

      // SpeechRecognition: window.SpeechRecognition || window.webkitSpeechRecognition,
      SpeechRecognition: window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition,
      recognition: null,
      voiceEnable: true
    }
  },
  components: {
    // DelegateTaskComp,
    // TaskCommentsComp,
    // AddSpectatorComp
    DatePicker
  },
  async mounted () {
    try {
      getUserActive()
        .then(res => {
          this.userId = res.id
          this.organizationId = res.organization_id
          this.getTypeList()
          getOrganizationsUsers(this.organizationId)
            .then((res) => {
              res.forEach(user => {
                if(user.status === "ACCEPTED") {
                  getUserById(user.user_id)
                  .then(res => {
                    this.orgUsers.push(res.data)
                  })
                }
              })
            })
        })
      this.getTask()
      // await Promise.all([this.getTask(), this.getMiniTasks(), this.getSpectators()])
    } catch (error) {
      console.log(error)
    }
    this.startListening()
  },
  methods: {
    negBtn () {
      this.negativebtn = true
      this.showComments()
    },
    async getTask () {
      axios.get(`${process.env.VUE_APP_SERVER_URL}tasks/${this.id}`, { headers: Header.auth })
        .then(response => {
          const date = new Date()
          const offset = -date.getTimezoneOffset() / 60;
          this.task = response.data
          this.selectedText = this.task.text
          this.selectedPriority = this.task.priority
          if(response.data.type_id) {
            const typeIdx = this.typeList.findIndex(el => el.id === this.task.type_id)
            this.selectedTypeID = this.typeList[typeIdx].id
          }
          this.deadline = new Date(this.task.deadline)
          // this.deadline.setTime(this.deadline.getTime() + offset * 60 * 60 * 1000);
          if(this.task.checkpoint) {
            this.checkpoint = new Date(this.task.checkpoint)
            // this.checkpoint.setHours(this.checkpoint.getHours() + offset)
          }
          if(this.task.checkbox) {
            this.checkbox = new Date(this.task.checkbox)
            // this.checkbox.setHours(this.checkbox.getHours() + offset)
          }
          this.selectedUser = `${this.task.executor_name} ${this.task.executor_surname}`
          this.selectedUserId = this.task.executor_id
          this.loading = false
          // this.getComments()
          
          getTaskComments(response.data.id)
            .then(res => {
              this.comments = res.data
            })
        })
        .catch(() => {
          this.closeSidebar()
        })
    },
    getTypeList () {
      axios.get(`${process.env.VUE_APP_SERVER_URL}task_types/?organization_id=${this.organizationId}`, { headers: Header.auth })
        .then(res => {
          this.typeList = res.data
        })
        .catch(err => console.log(err))
    },
    getSpectators () {
      axios.get(`${process.env.VUE_APP_SERVER_URL}spectators/?task_id=${this.id}`, {
        params: { organization_id: this.organizationId },
        headers: Header.auth
      })
        .then(response => {
          this.spectators = response.data
        })
        .catch(() => {
        })
    },
    changeSelectedUser(user) {
      this.showDropdown = false
      this.selectedUser = `${user.name} ${user.surname}`
      this.selectedUserId = user.id
    },
    changeShowDropdown () {
      this.showDropdown = !this.showDropdown
    },
    visModaldate () {
      this.modalDate = !this.modalDate
      this.modalCheckpoint = false
      this.modalCheckbox = false
    },
    visModalCheckpoint () {
      this.modalCheckpoint = !this.modalCheckpoint
      this.modalDate = false
      this.modalCheckbox = false
    },
    visModalCheckbox () {
      this.modalCheckbox = !this.modalCheckbox
      this.modalDate = false
      this.modalCheckpoint = false
    },
    cancelDate () {
      this.modalDate = !this.modalDate
      this.deadline = null
    },
    cancelCheckpoint () {
      this.modalCheckpoint = !this.modalCheckpoint
      this.checkpoint = null
    },
    cancelCheckbox () {
      this.modalCheckbox = !this.modalCheckbox
      this.checkbox = null
    },

    showAddspecs () {
      this.show_addspec_window = true
    },
    showComments () {
      this.show_comments_window = true
    },
    closeSidebar () {
      this.voiceEnable = false
      this.recognition.stop()
      this.$emit('close')
    },
    createMinitask() {
      const data = {
        title: this.minitaskValue,
        text: "",
        organization_id: this.organizationId,
        task_id: this.task.id
      }
      this.loading = true
      this.minitaskValue = ''
      createMinitask(data)
        .then(res => this.getTask())
    },
    createComment () {
      const data = {
        task_id: this.task.id,
        text: this.commentValue,
        organization_id: this.organizationId
      }
      this.commentValue = ''
      createTaskComment(data)
        .then(res => this.getTask())
    },
    acceptTask (id) {
      this.loading = true
      axios
        .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${id}/wait`, null, {
          headers: Header.auth
        })
        .then(() => {
          this.voiceEnable = false
          this.recognition.stop()
          this.$emit('taskChange')
        })
        .catch((error) => {
          console.log(error)
          this.showError(error.response)
          this.loading = false
        })
    },
    acceptTaskEnding (id) {
      this.loading = true
      axios
        .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${id}/accept`, null, {
          headers: Header.auth
        })
        .then(() => {
          this.voiceEnable = false
          this.recognition.stop()
          this.$emit('taskChange')
        })
        .catch((error) => {
          console.log(error)
          this.showError(error.response)
          this.loading = false
        })
    },
    startTask (id, time) {
      this.loading = true
      startTask(id, time)
        .then(() => {
          this.voiceEnable = false
          this.recognition.stop()
          this.$emit('taskChange')
        })
        .catch((error) => {
          console.log(error)
          this.showError(error.response)
          this.loading = false
        })
    },
    mainResufeTask () {
      this.loading = true
      axios
        .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${this.task.id}/cancel`, null, {
          headers: Header.auth
        })
        .then(() => {
          this.voiceEnable = false
          this.recognition.stop()
          this.$emit('taskChange')
        })
        .catch((error) => {
          console.log(error)
          this.showError(error.response)
          this.loading = false
        })
    },
    completeTask (id) {
      this.loading = true
      axios
        .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${id}/complete`, null, {
          headers: Header.auth
        })
        .then(() => {
          this.voiceEnable = false

          if(this.task.creator_id === this.userId) {
            this.acceptTaskEnding(id)
          }
          else {
            this.recognition.stop()
            this.$emit('taskChange')
          }
        })
        .catch((error) => {
          console.log(error)
          this.showError(error.response)
          this.loading = false
        })
    },
    cancelTask () {
      this.voiceEnable = false
      this.recognition.stop()
      cancelTask(this.task.id)
    },
    resetCheckpoint () {
      this.checkpoint = null
    },
    resetCheckbox () {
      this.checkbox = null
    },
    updateTask () {
      const date = new Date(this.deadline)
      const offset = -date.getTimezoneOffset() / 60;
      const timezone = `+${String(offset).padStart(2, '0')}:${String(offset % 1 * 60).padStart(2, '0')}`
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const deadlineTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:00${timezone}`

      let checkpointTimestamp = null
      if (this.checkpoint) {
        const date = new Date(this.checkpoint)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        checkpointTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:00${timezone}`
      }

      let checkboxTimestamp = null
      if (this.checkbox) {
        const date = new Date(this.checkbox)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        checkboxTimestamp = `${year}-${month}-${day}T${hours}:${minutes}:00${timezone}`
      }
      const data = {}
      const currentDateMs = new Date().getTime()
      let deadlineMs = null
      let stringD1 = null
      const dateX = new Date(this.deadline)
      dateX.setHours(dateX.getHours() - offset)

      if(this.deadline) {
        stringD1 = String(dateX)
        const stringD2 = String(new Date(this.task.deadline))

        deadlineMs = new Date(this.deadline).getTime()
        const taskDeadlineMs = new Date(this.task.deadline).getTime()

        if (taskDeadlineMs === deadlineMs) {}
        else if (stringD1 !== stringD2 && deadlineMs > currentDateMs) data.deadline = deadlineTimestamp
        else if (stringD1 == stringD2) {}
        else {
          alert('Неправильно выбран дедлайн')
          return
        }
      }
      else {
        alert('Неправильно выбран дедлайн')
        return
      }

      if(this.checkpoint) {
        const dateY = new Date(this.checkpoint)
        dateY.setHours(dateY.getHours() - offset)
        const stringCp1 = String(dateY)
        const stringCp2 = String(new Date(this.task.checkpoint))

        const checkpointMs = new Date(this.checkpoint).getTime()
        const taskCheckpointMs = new Date(this.task.checkpoint).getTime()

        if (taskCheckpointMs === checkpointMs) {}
        else if (stringCp1 !== stringCp2 && checkpointMs < deadlineMs && checkpointMs > currentDateMs) data.checkpoint = checkpointTimestamp
        else if (stringCp1 == stringCp2) {}
        else {
          alert('Неправильно выбран чекпоинт')
          return
        }
      }
      else if (this.checkpoint === null) data.checkpoint = null

      if(this.checkbox) {
        const dateZ = new Date(this.checkbox)
        dateZ.setHours(dateZ.getHours() - offset)
        const stringCb1 = String(dateZ)
        const stringCb2 = String(new Date(this.task.checkbox))

        const taskCheckboxtMs = new Date(this.task.checkbox).getTime()
        const checkboxMs = this.checkbox.getTime()

        if (taskCheckboxtMs === checkboxMs) {}
        else if (stringCb1 !== stringCb2 && checkboxMs < deadlineMs && checkboxMs > currentDateMs) data.checkbox = checkboxTimestamp
        else if (stringCb1 == stringCb2) {}
        else {
          alert ('Неправильно выбран чекбокс')
          return
        }
      }
      else if (this.checkbox === null) data.checkbox = null

      if (this.selectedText !== this.task.text) data.text = this.selectedText
      if (this.selectedTypeID !== this.task.type_id && this.selectedTypeID) data.type_id = this.selectedTypeID
      if (this.selectedPriority !== this.task.priority) data.priority = this.selectedPriority
      if (this.selectedUserId !== this.task.executor_id) data.executor_id = this.selectedUserId

      if (this.task.creator_id !== this.$cookies.get('ID') && this.selectedUserId !== this.task.executor_id) {
        alert ('Только создатель задачи может менять исполнителя')
        return
      }

      this.loading = true
      const keys = Object.keys(data);
      if (keys.length) {
        updateTask(this.task.id, data)
          .then(res => {
            if (res) {
              // alert('Задача обновлена')
              // this.sidebarOpen = false
              this.$emit('taskUpdate')
            }
            this.loading = false
          })
      }
      else this.loading = false
    },
    deadlineExpired (deadline) {
      const dateMs = new Date().getTime()
      const taskDateMs = new Date(deadline).getTime()
      if (dateMs > taskDateMs) return true
      return false
    },
    closeTask () {
      this.voiceEnable = false
      this.recognition.stop()
      this.$emit('close')
    },
    getStatus (status) {
      switch (status) {
        case 'new':
          return 'Новая'
        case 'working':
          return 'В работе'
        case 'completed':
          return 'Завершенные'
        case 'accepted':
          return 'Проверенные'
        case 'successful':
          return 'Отклонено'
        default:
          return status
      }
    },
    getClass (status) {
      switch (status) {
        case 'new':
          return 'sb_new'
        case 'working':
          return 'sb_working'
        case 'completed':
          return 'sb_completed'
        case 'accepted':
          return 'sb_accepted'
        case 'successful':
          return 'sb_successful'
        default:
          return ''
      }
    },
    formatDate (date) {
      const d = new Date(date)
      const offset = -d.getTimezoneOffset() / 60
      d.setHours(d.getHours() + offset)

      const hours = String(d.getHours()).padStart(2, '0')
      const minutes = String(d.getMinutes()).padStart(2, '0')
      const time = `${hours}:${minutes}`

      const dateStr = d.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })

      return `${time} ${dateStr}`
    },
    startListening () {
      this.recognition = new this.SpeechRecognition()
      this.recognition.lang = 'ru'

      const ArrAceptTask = [
        'принять', 'прими'
      ]
      const ArrCompleteTask = [
        'завершить', 'заверши'
      ]
      const ArrDeclineTask = [
        'отклонить', 'отмена'
      ]
      const ArrConfirmTask = [
        'подтвердить', 'подтверди'
      ]
      const ArrCloseTask = [
        'закрыть', 'закрой'
      ]

      this.recognition.onresult = async (event) => {
        const transcript = event.results[0][0].transcript.toLowerCase()
        // const transcriptArr = transcript.split(' ')
        const transcriptArr = transcript.split(/[,;: ]+/)
        console.log('start', transcript)
        for (let q = 0; q < transcriptArr.length; q++) {
          // filter
          const lastChar = transcriptArr[q].charAt(transcriptArr[q].length - 1)
          if (lastChar === '.' || lastChar === ',') transcriptArr[q] = transcriptArr[q].slice(0, -1)
          if (transcriptArr[q] === 'ноль') transcriptArr[q] = 0
          if (transcriptArr[q] === transcriptArr[q - 1]) transcriptArr.splice(q, 1)
        }

        for (let i = 0; i < transcriptArr.length; i++) {
          const word = transcriptArr[i]
          if (ArrAceptTask.includes(word)) {
            if (this.task.status === 'new') this.startTask(this.task.id, this.time)
          } else if (ArrCompleteTask.includes(word)) {
            if (this.task.status === 'working') this.completeTask(this.task.id)
          } else if (ArrConfirmTask.includes(word)) {
            if (this.task.status === 'completed') this.acceptTaskEnding(this.task.id)
          } else if (ArrDeclineTask.includes(word)) {
            this.mainResufeTask()
          } else if (ArrCloseTask.includes(word)) {
            this.closeTask()
          }
        }
      }
      this.recognition.onend = async () => {
        this.recognition.stop()
        if (this.voiceEnable) this.recognition.start()
      }
      this.recognition.start()
    },
    convertPriority (priority) {
      if (priority === 'very_low') return 'очень низкий'
      else if (priority === 'low') return 'низкий'
      else if (priority === 'high') return 'высокий'
      else if (priority === 'very_high') return 'очень высокий'
      return 'средний'
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    taskUpdate: { Function }
  },
  computed: {
    canEdit () {
      return this.sidebarOpen && this.editMode
    },
    filteredOrgUsers () {
      const filtered = []
      const searchQuery = this.selectedUser
      if (this.selectedUser.length >= 0) {
        this.orgUsers.map((el) => {
          if (el.name.toLowerCase().includes(searchQuery.toLowerCase())) filtered.push(el)
          else if (el.surname.toLowerCase().includes(searchQuery.toLowerCase())) filtered.push(el)
        })
      } else {
        this.showDropdown = true
      }
      return filtered
    },
    formattedDeadline () {
      if (this.deadline) {
        const date = new Date(this.deadline)
        const formattedDate = date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
        return formattedDate
      } else {
        return ''
      }
    },
    formattedCheckpoint () {
      if (this.checkpoint) {
        const date = new Date(this.checkpoint)
        const formattedDate = date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
        return formattedDate
      } else {
        return ''
      }
    },
    formattedCheckbox () {
      if (this.checkbox) {
        const date = new Date(this.checkbox)
        const formattedDate = date.toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
        return formattedDate
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  width: 626px;
  height: 852px;
  background: #3E3E42;
  border-left: 1px solid rgba(255, 255, 255, 0.116);
  border-radius: 25px 0px 0px 25px;
  z-index: 300;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow-y: none;
}

.sidebar_container {
  width: 100%;
  height: 100%;
}

.sidebar_header {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  height: 105px;
  border-bottom: 1px solid #ECC22C;
}

.header_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sidebar_header_btns {
  display: flex;
  position: relative;
  top: -20px;
}

.header_btn {
  position: relative;
  width: 130px;
  height: 25px;
  bottom: 5px;
  background: #ECC22C;
  border: none;
  border-radius: 5px;
}

.sidebar_task_title {
  position: relative;
  width: 100%;
  left: 0px;
}

.sidebar_task_title p {
  text-align: left;
  font-size: 16px;
}

.sidebar_header h1 {
  color: white;
  text-align: left;
}

.sidebar_deadline {
  position: relative;
  width: 100px;
  right: 0px
}

.sidebar_deadline a {
  color: white;
}

.sidebar_information {
  margin-top: 10px;
  position: relative;
  left: -0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}

.information::-webkit-scrollbar {
  width: 10px;
}

.information::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.information::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.information::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

/* Этот стиль будет добавляться при прокрутке элемента */
.information::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

.info {
  display: flex;
  justify-content: space-between;
  margin-top: 30px
}

.info_text {
  margin-left: 10px;
  top: -3px
}

.info a {
  color: white;
  position: relative;
  bottom: 10px;
}

.info_pallete {
  position: relative;
  top: -15px;
  left: -300px;
  min-width: 105px;
  height: 26px;
  background: #82838A;
  border-radius: 3.52041px;
}

.info_pallete_span {
  position: relative;
  top: -15px;
  left: -300px;
  min-width: 105px;
  height: 26px;
}

.info_pallete_long {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  top: -10px;
  left: -115px;
  min-width: 290px;
  height: 26px;
  background: #82838A;
  border-radius: 3.52041px;
}

.info_pallete_long p {
  font-size: 13px;
  position: relative;
  left: 8px;
  color: white;
}

.info_pallete_long img {
  position: relative;
  left: 6px;
  background-color: white;
  border-radius: 50px;
}

.info_pallete_long_specs {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 5px;
  top: -10px;
  left: -115px;
  min-width: 290px;
  height: 26px;
  background: #82838A;
  border-radius: 3.52041px;
}

.info_pallete_long_specs p {
  font-size: 13px;
  position: relative;
  left: 8px;
  color: white;
}

.info_pallete_long_specs img {
  position: relative;
  left: 6px;
  background-color: white;
  border-radius: 50px;
}

.add_spectator p {
  color: #2D2D2D;
  font-family: sans-serif;
  cursor: pointer;
}

.negative_status {
  background: #7c0101 !important;
}

.wrapper_commint_negative {
  background: #3F5D80;
  position: absolute;
  top: -364px;
  width: 300px;
  height: 247px;
  border-radius: 12px;
}

.name {
  color: white;
  font-family: sans-serif;
  font-size: 15px;

}

h1[contenteditable] {
  background-color: #333;
  color: white;
  margin: 0 auto;
}

h1[contenteditable]:not(:focus) {
  outline: none;
  cursor: default;
}

h4 {
  font-size: 12px;
  font-family: sans-serif;
  color: white;
}

.hours {
  position: absolute;
  bottom: 180px;
  color: white;
  font-family: sans-serif;
}

.not_executor {
  position: absolute;
  color: white;
  font-size: 16px;
  bottom: 120px;
}

.not_creater {
  position: absolute;
  color: white;
  font-size: 16px;
  bottom: 85px;
}

.slider {
  position: absolute;
  bottom: 140px;
  width: 140px;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
}

.text {
  width: 300px;
  height: 200px;
}

.subtasks {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #2D2D2D;
  font-size: 20px;
  font-family: sans-serif;
  margin-top: 5px;
}

.subtasks p {
  font-size: 18px;
}

.subtask {
  display: flex;
  justify-content: center;
  width: 482px;
  /* уменьшаем ширину на отступ скроллбара */
  height: 160px;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 20px;
  /* добавляем отступ слева */
  background: #fff;
  border-radius: 23px 10px 5px 23px;
  overflow-y: scroll;
  padding-right: 10px;
  /* добавляем отступ справа */
  border: 1px solid black;
}

.subtask::-webkit-scrollbar {
  width: 10px;
}

.subtask::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.subtask::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.subtask::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.subtask::-webkit-scrollbar-thumb:active {
  background-color: #777;
}

.subtask_render {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 400px;
  height: 32px;
  background: #F4F4F4;
  border-radius: 5px;
}

.subtask_buttons {
  position: relative;
  left: 35px;
}

.subtask_edit {
  cursor: pointer;
}

.subtask_delete {
  margin-left: 5px;
  cursor: pointer;
}

.subtask li {
  position: relative;
  list-style: none;
  top: 2px;
  left: 20px
}

.accepts {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 12px;
}

.start-task-btn, .start-task-btn-dark {
  position: relative;
  width: 154px;
  height: 40px;
  border: none;
  border-radius: 5.53226px;
  font-size: 15px;
  margin: 0 5px;
  cursor: pointer;
  transition: .5s;
}
.start-task-btn {
  background-color: var(--light-task-completed-bg);
  color: var(--light-task-color);
}
.start-task-btn:hover {
  background-color: var(--light-task-completed-bg-hover);
}
.start-task-btn-dark {
  background-color: var(--dark-task-completed-bg);
  color: var(--dark-task-color);
}
.start-task-btn-dark:hover {
  background-color: var(--dark-task-completed-bg-hover);
}

.negative {
  background: var(--light-task-decline-bg);
}
.negative:hover {
  background: var(--light-task-decline-bg-hover);
}
.negative-dark {
  background: var(--dark-task-decline-bg);
  color: var(--dark-task-color);
}
.negative-dark:hover {
  background:var(--dark-task-decline-bg-hover);
}

.vioce_resp, .vioce_resp-dark {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vioce_resp {
  background: var(--light-task-new-bg);
}
.vioce_resp:hover {
  background: var(--light-task-new-bg-hover);
}
.vioce_resp-dark {
  background: var(--dark-task-new-bg);
}
.vioce_resp-dark {
  background: var(--dark-task-new-bg-hover);
}

.vioce_resp span {
  font-size: 15px;
}

.vioce_resp img {
  width: 20px;
  height: 20px;
}
.complete-task-btn, .complete-task-btn-dark {
  position: relative;
  width: 154px;
  height: 40px;
  background: #3F5D80;
  color: white;
  border: none;
  border-radius: 5.53226px;
  font-size: 15px;
  transition: .5s;
}
.complete-task-btn {
  background: var(--light-task-working-bg);
  color: var(--light-task-color);
}
.complete-task-btn:hover {
  background: var(--light-task-working-bg-hover);
}
.complete-task-btn-dark {
  background: var(--dark-task-working-bg);
  color: var(--dark-task-color);
}
.complete-task-btn-dark:hover {
  background: var(--dark-task-working-bg-hover);
}

.slides-fade-enter-active {
  transition: all .4s ease;
  opacity: 0;
}

.slides-fade-leave-active {
  transition: all .4s ease;
  opacity: 1;
}

.slides-fade-enter-to {
  opacity: 1;
  animation: ani forwards;
}

.slides-fade-leave-to {
  animation: ani forwards;
  opacity: 0;
}

.sb_new {
  padding: 5px 15px;
  height: 12px;
  background: rgb(125, 145, 68);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  color: #D9D9D9;
}

.sb_successfuled {
  padding: 5px 15px;
  height: 12px;
  background: rgba(255, 0, 0, 0.657);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  color: #D9D9D9
}

.sb_working {
  padding: 5px 15px;
  height: 12px;
  background: rgb(63, 93, 128);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  color: #D9D9D9;
}

.sb_completed {
  padding: 5px 15px;
  height: 12px;
  background: rgb(63, 128, 73);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  color: #D9D9D9;
}

.sb_accepted {
  padding: 1px 1px;
  height: 12px;
  background: rgb(26, 70, 33);
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  color: #D9D9D9;
}


.inactive {
  opacity: 0.8;
}


.error_modal {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 5px;
  width: auto;
  max-width: 40%;
  height: auto;
  min-height: 50px;
  color: white;
  background-color: #FF6161;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.error_modal-content {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.error_modal-content p {
  font-family: mulish;
  font-size: 15px;
}

.error-enter-active {
  animation: slide-down 0.5s;
}

.error-leave-active {
  animation: slide-up 0.5s;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
