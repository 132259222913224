<template>
  <div class="workspace" :class="{'dark-bg-primary': theme === 'dark'}">
    <div class="loading" v-if="loading"></div>

    <div class="modal-global" v-if="!loading && visibleSettings">
      <div class="modal-container" :class="{'dark-bg-primary': theme === 'dark'}">
        <div class="modal-header">
          <div style="display: flex; align-items: center; gap: 4px;">
            <span :class="{'dark-text': theme === 'dark'}">Настройки поиска</span>
          </div>
          <button @click="visibleSettings = !visibleSettings" :class="{'dark-text': theme === 'dark'}">×</button>
        </div>
        <div class="modal-body" >
          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Создатель</p>
            <input type="text" v-model="selectedUserCreator" @click="changeShowDropdownCreator" :class="{'dark-input': theme === 'dark'}"
              required
            />
            <button @click="resetCreator" :class="{'dark-text': theme === 'dark'}" class="modal-input-wrapper-close">×</button>
            <ul v-if="showDropdownCreator" class="user-list" style="top:32px !important; max-height: 200px;  overflow-y: auto;">
              <li v-for="user in orgUsers" :key="user.id" @click.prevent="changeSelectedUserCreator(user)">
                {{ user.name }} {{ user.surname }} {{ user.patronymic }}
              </li>
            </ul>
          </div>

          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Исполнитель</p>
            <input type="text" v-model="selectedUserExecutor" @click="changeShowDropdownExecutor" :class="{'dark-input': theme === 'dark'}"
              required
            />
            <button @click="resetExecutor" :class="{'dark-text': theme === 'dark'}" class="modal-input-wrapper-close">×</button>
            <ul v-if="showDropdownExecutor" class="user-list" style="top:32px !important; max-height: 200px;  overflow-y: auto;">
              <li v-for="user in orgUsers" :key="user.id" @click.prevent="changeSelectedUserExecutor(user)">
                {{ user.name }} {{ user.surname }} {{ user.patronymic }}
              </li>
            </ul>
          </div>

          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Роль</p>
            <select
              v-model="filterType"
              @change="fetchTasks"
              :disabled="isAdminMode"
              :class="{'dark-input': theme === 'dark'}"
            >
              <option class="filter_option" value="me_participant">
                С моим участием
              </option>
              <option class="filter_option" value="user">Я исполнитель</option>
              <option class="filter_option" value="me_creator">Я создатель</option>
              <option class="filter_option" value="me_spectator">Я наблюдатель</option>
            </select>
          </div>

          <div class="modal-input-wrapper">
            <p :class="{'dark-text': theme === 'dark'}">Поиск по названию</p>
            <input v-model="searchValue" type="text" placeholder="Поиск" :class="{'dark-input': theme === 'dark'}">
          </div>

          <button @click="resetFilter" class="layout-header-btn" :class="{'dark-bg-button': theme === 'dark'}">
            <span>Сбросить</span>
          </button>

        </div>
      </div>
    </div>

    <div class="workspace-header-search">
      <h3>Доска задач</h3>
      <div class="workspace-header-search-flex">
        <!-- <input v-model="searchValue" type="text" placeholder="Поиск" :class="{'dark-input-layout': theme === 'dark'}">
        <select
          v-model="filterType"
          @change="fetchTasks"
          :disabled="isAdminMode"
          class="layout-header-search-select"
          :class="{'dark-input-layout': theme === 'dark'}"
        >
          <option class="filter_option" value="me_participant">
            С моим участием
          </option>
          <option class="filter_option" value="user">Я исполнитель</option>
          <option class="filter_option" value="me_creator">Я создатель</option>
          <option class="filter_option" value="me_spectator">Я наблюдатель</option>
        </select> -->

        <button @click="visibleSettings = !visibleSettings" class="layout-header-btn" :class="{'dark-bg-button': theme === 'dark'}" style="margin-right: 12px;">
          <span>Настройки поиска</span>
        </button>
        <button @click="showCreateTask" class="messenger-header-btn">
          <span>Новая задача</span>
          <div>+</div>
        </button>
      </div>
    </div>
    <div class="workspace-layout">
      <button @click="changeActiveButton(1, activeButton1)" :class="{'workspace-layout-btn-active': activeButton1}" class="workspace-layout-btn">Новые</button>
      <button @click="changeActiveButton(2, activeButton2)" :class="{'workspace-layout-btn-active': activeButton2}" class="workspace-layout-btn">В работе</button>
      <button @click="changeActiveButton(3, activeButton3)" :class="{'workspace-layout-btn-active': activeButton3}" class="workspace-layout-btn">На проверке</button>
      <button @click="changeActiveButton(4, activeButton4)" :class="{'workspace-layout-btn-active': activeButton4}" class="workspace-layout-btn">Завершенные</button>
    </div>

    <div class="workspace-content">
      <!-- <div class="error" v-if="isEmptyTasks && !loading && !error">
        Доска задач пуста...
      </div> -->
      <div v-if="activeButton1" @dragover.prevent @drop="drop('new', $event)" class="workspace-content-column" :class="{'dark-bg-secondary': theme === 'dark'}">
        <h3>Новые</h3>
        <div v-if="!filteredTasks.new.length">Нет задач</div>
        <div
          v-for="task in filteredTasks.new"
          :key="task.id"
          @click="showTaskBar(task)"
          draggable="true"
          @dragstart="dragStart( task, 'new', $event)"
          @drag="dragContinue(task, $event)"
          class="workspace-content-column-el"
          :class="{'dark-bg-primary': theme === 'dark'}"
        >
          <div style="display: flex; align-items: center; gap: 4px;">
            <div class="status-circle" :class="{'status-circle_negative': deadlineExpired(task.deadline) }"></div>
            <h4>{{ task.title }}</h4>
          </div>

          <div class="workspace-content-column-flex">
            <img :src="getImgUrl(task.creator_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.creator_surname }} </div>
            <div style="color: #000; padding-left: 6px; padding-right: 4px"> → </div>
            <img :src="getImgUrl(task.executor_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.executor_surname }} </div>
              </div>
              <p><span :class="[getClass(task.status, task.successful)]">
                {{ task.successful === false ? 'Отклонено' : get_status(task.status) }}</span>
              </p>
            </div>
      </div>
      <div v-if="activeButton2" @dragover.prevent @drop="drop('working', $event)" class="workspace-content-column" :class="{'dark-bg-secondary': theme === 'dark'}">
        <h3>В работе</h3>
        <div v-if="!filteredTasks.working.length">Нет задач</div>
        <div
          v-for="task in filteredTasks.working"
          :key="task.id"
          @click="showTaskBar(task)"
          draggable="true"
          @dragstart="dragStart(task, 'working', $event)"
          @drag="dragContinue(task, $event)"
          class="workspace-content-column-el"
          :class="{'dark-bg-primary': theme === 'dark'}"
        >
          <div style="display: flex; align-items: center; gap: 4px;">
            <div class="status-circle" :class="{'status-circle_negative': deadlineExpired(task.deadline) }"></div>
            <h4>{{ task.title }}</h4>
          </div>
          <div class="workspace-content-column-flex">
            <img :src="getImgUrl(task.creator_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.creator_surname }} </div>
            <div style="color: #000; padding-left: 6px; padding-right: 4px"> → </div>
            <img :src="getImgUrl(task.executor_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.executor_surname }} </div>
          </div>
          <p><span :class="[getClass(task.status, task.successful)]">
            {{ task.successful === false ? 'Отклонено' : get_status(task.status) }}</span>
          </p>
        </div>
      </div>

      <div v-if="activeButton3" @dragover.prevent @drop="drop('completed', $event)" class="workspace-content-column" :class="{'dark-bg-secondary': theme === 'dark'}">
        <h3>На проверке</h3>
        <div v-if="!filteredTasks.completed.length">Нет задач</div>
        <div
          v-for="task in filteredTasks.completed"
          :key="task.id"
          @click="showTaskBar(task)"
          draggable="true"
          @dragstart="dragStart(task, 'completed', $event)"
          @drag="dragContinue(task, $event)"
          class="workspace-content-column-el"
          :class="{'dark-bg-primary': theme === 'dark'}"
        >
        <div style="display: flex; align-items: center; gap: 4px;">
          <div class="status-circle" :class="{'status-circle_negative': deadlineExpired(task.deadline) }"></div>
          <h4>{{ task.title }}</h4>
        </div>
          <div class="workspace-content-column-flex">
            <img :src="getImgUrl(task.creator_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.creator_surname }} </div>
            <div style="color: #000; padding-left: 6px; padding-right: 4px"> → </div>
            <img :src="getImgUrl(task.executor_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.executor_surname }} </div>
          </div>
          <p><span :class="[getClass(task.status, task.successful)]">
            {{ task.successful === false ? 'Отклонено' : get_status(task.status) }}</span>
          </p>
        </div>
      </div>

      <div v-if="activeButton4" @dragover.prevent @drop.self="drop('accepted', $event)" class="workspace-content-column" :class="{'dark-bg-secondary': theme === 'dark'}">
        <h3>Завершенные</h3>
        <div v-if="!filteredTasks.accepted.length">Нет задач</div>
        <div
          v-for="task in filteredTasks.accepted"
            :key="task.id"
            @click="showTaskBar(task)"
            draggable="true"
            @dragstart="dragStart(task, 'accepted', $event)"
            @drag="dragContinue(task, $event)"
            class="workspace-content-column-el"
            :class="{'dark-bg-primary': theme === 'dark'}"
          >
          <div style="display: flex; align-items: center; gap: 4px;">
            <div class="status-circle" :class="{'status-circle_negative': deadlineExpired(task.deadline) }"></div>
            <h4>{{ task.title }}</h4>
          </div>
          <div class="workspace-content-column-flex">
            <img :src="getImgUrl(task.creator_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.creator_surname }} </div>
            <div style="color: #000; padding-left: 6px; padding-right: 4px"> → </div>
            <img :src="getImgUrl(task.executor_photo_url)" width="35" height="35" alt="photo" class="card_img" />
            <div> {{ task.executor_surname }} </div>
          </div>
          <p><span :class="[getClass(task.status, task.successful)]">
            {{ task.successful === false ? 'Отклонено' : get_status(task.status) }}</span>
          </p>
        </div>
      </div>
    </div>

    <CreateTaskComp v-model:show="show_task_window" @taskCreated="taskUpdate" @close="closeCreateTask"></CreateTaskComp>
    <Sidebar v-if="showSidebar" :data="dataTask" @close="closeTaskBar" @taskChange="taskUpdate" @taskUpdate="taskUpdate"></Sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import VueCookies from 'vue-cookies'
import Sidebar from "@/components/UniversalComps/Sidebar.vue"
import CreateTaskComp from "@/components/UniversalComps/CreateTaskComp.vue"
import EditTaskComp from "@/components/UniversalComps/EditTaskComp.vue"
import ConfirmationComp from "@/components/WorkSpace/ConfirmationComp.vue"
import UserMotitor from "@/components/UniversalComps/UserMotitorComp.vue"
import { Header } from '@/api/index'
import axios from "axios"
import levenshteinDistance from '@/mixins/LevenshteinDistance.vue'
import setToCoockie from '@/mixins/SetCoockie.vue'
import getClass from '@/mixins/GetClass.vue'

import { getUserActive, getOrganizationsUsers, getUserById } from '@/api/get'
import {
  postTextToSpeech,
  getUserTasks,
  getAllTasks,
  getCreatorTasks,
  getSpectatorTasks
} from "@/api/post"


export default {
  name: "VueQuillEditorComponent",
  components: {
    Sidebar,
    CreateTaskComp,
    EditTaskComp,
    UserMotitor,
    ConfirmationComp,
    setToCoockie
  },
  mixins: [getClass, levenshteinDistance, setToCoockie],
  data() {
    return {
      tasks: {
        new: [],
        working: [],
        completed: [],
        accepted: [],
      },
      allTasks: [],
      loading: true,
      error: false,
      showTasks: false,
      draggedTask: null,
      draggedFromColumn: null,
      showSidebar: false,
      show_task_window: false,
      show_edit_window: false,
      show_confirmation_window: false,
      showClue: false,
      dataTask: {},
      contentHtml: "",
      searchValue: "",
      sortOption: "",
      filterType: "me_participant",
      isAdminMode: false,
      isSuperuser: false,
      userId: this.$cookies.get("ID"),
      createrID: this.$cookies.get("ID"),
      executorID: this.$cookies.get("ID"),
      organizationId: '',
      theme: VueCookies.get('THEME'),
      isDragStart: false,
      activeButton1: false,
      activeButton2: false,
      activeButton3: false,
      activeButton4: false,
      screenWidth: '',
      countColumn: 1,
      offsetX: 0,
      offsetY: 0,
      dragItem: null,
      dragItemId: null,
      SpeechRecognition: window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition,
      recognition: null,
      voiceEnable: true,
      voiceEnableActive: false,
      loading: true,
      visibleSettings: false,
      orgUsers: [],
      selectedUserCreator: '',
      selectedUserCreatorId: '',

      selectedUserExecutor: '',
      selectedUserExecutorId: '',

      selectedUserId: '',
      showDropdown: false,
      showDropdownCreator: false,
      showDropdownExecutor: false,

      interval: null
    }
  },
  created: function() {
    this.screenWidth = window.innerWidth
    window.addEventListener('resize', this.handleResize)
  },
  computed: {
    filteredTasks() {
      const filtered = {
        new: [],
        working: [],
        completed: [],
        accepted: [],
      }
      for (const column in this.tasks) {
        filtered[column] = this.tasks[column].filter((task) => {
          const { task_title, title } = task
          const searchRegexp = new RegExp(this.searchValue, "i")

          return searchRegexp.test(task_title) || searchRegexp.test(title)
        })
        filtered[column] = filtered[column].filter(task =>
          task.creator_id === this.selectedUserCreatorId || !this.selectedUserCreatorId
        )
        filtered[column] = filtered[column].filter(task =>
          task.executor_id === this.selectedUserExecutorId || !this.selectedUserExecutorId
        )
      }

      if (this.sortOption === "newest") {
        filtered.new.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        filtered.working.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        filtered.completed.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        filtered.accepted.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      } else if (this.sortOption === "oldest") {
        this.tasks.new.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        this.tasks.working.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        this.tasks.completed.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        this.tasks.accepted.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      } else if (this.sortOption === "deadline") {
        filtered.new.sort(
          (a, b) =>
            new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
        )
        filtered.working.sort(
          (a, b) =>
            new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
        )
        filtered.completed.sort(
          (a, b) =>
            new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
        )
        filtered.accepted.sort(
          (a, b) =>
            new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
        )
      } else if (this.sortOption === "priority_down") {
        const priorityOrder = {
          very_low: 0,
          low: 1,
          average: 2,
          high: 3,
          very_high: 4,
        }
        filtered.new.sort(
          (a, b) => priorityOrder[b.priority] - priorityOrder[a.priority]
        )
        filtered.working.sort(
          (a, b) => priorityOrder[b.priority] - priorityOrder[a.priority]
        )
        filtered.completed.sort(
          (a, b) => priorityOrder[b.priority] - priorityOrder[a.priority]
        )
        filtered.accepted.sort(
          (a, b) => priorityOrder[b.priority] - priorityOrder[a.priority]
        )
      }

      return filtered

    },
    isEmptyTasks() {
      const tasksArray = Object.values(this.tasks).flat()
      return tasksArray.length === 0
    },
  },
  async mounted() {
    if (localStorage.getItem('tasks')) {
      this.allTasks = JSON.parse(localStorage.getItem('tasks'))
      this.tasks.new = this.allTasks.filter((task) => task.status === "new")
      this.tasks.working = this.allTasks.filter((task) => task.status === "working")
      this.tasks.completed = this.allTasks.filter(
        (task) => task.status === "completed"
      )
      this.tasks.accepted = this.allTasks.filter(
        (task) => task.status === "accepted"
      )
      this.tasks.successful = this.allTasks.filter(
        (task) => task.status === "successful"
      )
      this.loading = false
    }
    try {
      if(this.$cookies.get('ID') && this.$cookies.get('ORGANIZATION_ID')) {
        this.userId = this.$cookies.get('ID')
        this.organizationId = this.$cookies.get('ORGANIZATION_ID')
        // this.startListening()
        this.handleResize()
        this.fetchTasks()
        this.interval = setInterval(this.fetchTasks, 5000)
      }
      else {
        getUserActive()
        .then(res => {
          this.userId = res.id
          this.organizationId = res.organization_id
          this.$cookies.set('ID', this.userId)
          this.$cookies.set('ORGANIZATION_ID', this.organizationId)
          // this.startListening()
          this.handleResize()
          this.fetchTasks()
          this.interval = setInterval(this.fetchTasks, 5000)
        })
        .catch((er) => {
          router.push({ name: 'Login' })
        })
      }
      getOrganizationsUsers(this.organizationId)
        .then((response) => {
          response.forEach(user => {
            if(user.status === "ACCEPTED") {
              getUserById(user.user_id)
              .then(res => {
                this.orgUsers.push(res.data)
              })
            }
          })
        })
    }
    catch {
      router.push({ name: 'Login' })
    }
  },
  methods: {
    async fetchTasks() {
      const filterTasks = (tasks) => {
        this.allTasks = tasks
        this.tasks.new = tasks.filter((task) => task.status === "new")
        this.tasks.working = tasks.filter((task) => task.status === "working")
        this.tasks.completed = tasks.filter(
          (task) => task.status === "completed"
        )
        this.tasks.accepted = tasks.filter(
          (task) => task.status === "accepted"
        )
        this.tasks.successful = tasks.filter(
          (task) => task.status === "successful"
        )
        this.loading = false
        this.showTasks = true
        this.error = false
        const tasksString = JSON.stringify(this.allTasks)
        localStorage.setItem('tasks', tasksString)
        this.loading = false
      }
      try {
        let tasks = []
        if (this.isAdminMode) {
          const { data } = await getAllTasks(this.createrID, this.organizationId)
          filterTasks(data)
        } else if (this.filterType === "user") {
          const { data } = await getUserTasks(this.userId, this.organizationId)
          filterTasks(data)
        } else if (this.filterType === "me_creator") {
          const { data } = await getCreatorTasks(this.createrID, this.organizationId)
          filterTasks(data)
        } else if (this.filterType === "me_spectator") {
          const { data } = await getSpectatorTasks(this.userId, this.organizationId)
          filterTasks(data)
        } else if (this.filterType === "me_participant") {
          const p1 = new Promise((resolve, reject) => { resolve(getUserTasks(this.userId, this.organizationId)) })
          const p2 = new Promise((resolve, reject) => { resolve(getCreatorTasks(this.userId, this.organizationId)) })
          const p3 = new Promise((resolve, reject) => { resolve(getSpectatorTasks(this.userId, this.organizationId)) })

          Promise.all([p1, p2, p3]).then((values) => {
            const tasks = values[0].data.concat(values[1].data).concat(values[2].data)
            const uniqueTasks = tasks.filter(
              (task, index, self) =>
                index === self.findIndex((t) => t.id === task.id)
            )
            filterTasks(uniqueTasks)
          })
        }
      } catch (error) {
        this.loading = false
        this.error = true
      }
    },
    getImgUrl(url) {
      return `${process.env.VUE_APP_SERVER_URL}${url}`
    },
    closeTaskBar () {
      this.showSidebar = false
      this.voiceEnable = true
      // this.startListening()
    },
    updateList () {
      this.fetchTasks()
    },

    async updateTaskStatus(task, status) {
      const time = 2
      if (status === "working") {
        try {
          await axios
            .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${task.id}/work?time=${time}`, null, {
              headers: Header.auth
            })
          this.tasks.working.push(task)
        } catch (error) {}
      } else if (status === "completed") {
        try {
          await axios
            .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${task.id}/complete?time=${time}`, null, {
              headers: Header.auth
            })
        } catch (error) {}
      } 
      else if (status === "accepted") {
        try {
          await axios
            .patch(`${process.env.VUE_APP_SERVER_URL}tasks/${task.id}/accept?time=${time}`, null, {
              headers: Header.auth
            })
        } catch (error) {}
      }
    },

    dragStart(task, fromColumn, e) {
      this.dragItem = e.target
      this.dragItemId = task.id
      this.draggedTask = task
      this.draggedFromColumn = fromColumn
      this.offsetX = e.clientX - this.dragItem.getBoundingClientRect().left
      this.offsetY = e.clientY - this.dragItem.getBoundingClientRect().top
      this.offsetY =  e.clientY - this.dragItem.getBoundingClientRect().top
    },
    dragContinue(task, e) {
      e.preventDefault()
      this.dragItem.style.position = 'absolute'
      this.dragItem.style.left = `${e.clientX - this.offsetX}px`
      this.dragItem.style.top = `${window.pageYOffset + e.clientY - this.offsetY}px`
    },

    closeCreateTask () {
      this.voiceEnable = true
      // this.startListening()
      this.show_task_window = false
    },
    showTaskBar(task) {
      this.showSidebar = false
      this.dataTask = task
      this.showSidebar = true
      this.showClue = false
      this.voiceEnable = false
      // this.recognition.stop()
    },
    showCreateTask() {
      this.show_task_window = true
      this.voiceEnable = false
      // this.recognition.stop()
    },
    showConfirmation(task) {
      this.dataTask = task
      this.show_confirmation_window = true
    },
    showEditTask(task) {
      this.dataTask = task
      this.show_edit_window = true
    },
    taskUpdate() {
      this.showSidebar = false
      this.show_task_window = false
      this.voiceEnable = true
      // this.startListening()
      this.fetchTasks()
    },
    async drop(toColumn, e) {
      e.preventDefault()
      this.dragItem.style.display = 'none'
      let dropElem = document.elementFromPoint(event.clientX, event.clientY)
      this.dragItem.style.display = 'block'
      let dropDiv = null
      let dropColumn

      function findClass (div) {
        if (div.classList.contains('workspace-content-column')) {
          dropDiv = div.firstElementChild.textContent
          if (dropDiv === 'Новые') dropColumn = 'new'
          else if (dropDiv === 'В работе') dropColumn = 'working'
          else if (dropDiv === 'На проверке') dropColumn = 'completed'
          else if (dropDiv === 'Завершенные') dropColumn = 'accepted'
        }
        else {
          if (div.parentNode) {
            findClass(div.parentNode)
          }
        }
      }

      findClass(dropElem)

      this.dragItem.style.position = 'relative'
      this.dragItem.style.left = 'initial'
      this.dragItem.style.top = 'initial'
      this.dragItem = null

      if (this.draggedFromColumn ==='new' && dropColumn === 'working' 
        || this.draggedFromColumn ==='working' && dropColumn === 'completed' 
        || this.draggedFromColumn ==='completed' && dropColumn === 'accepted'
        ){
        let idxRemove = this.tasks[this.draggedFromColumn].findIndex((el) => el.id === this.dragItemId)
        let dragableItem = this.tasks[this.draggedFromColumn][idxRemove]
        dragableItem.status = dropColumn
        this.tasks[this.draggedFromColumn].splice(idxRemove, 1)
        this.tasks[dropColumn].push(dragableItem)
      }
      await this.updateTaskStatus(this.draggedTask, dropColumn)
        .then((response) => {
          this.tasks[dropColumn].pop
          this.fetchTasks()
        })

      this.draggedTask = null
      this.draggedFromColumn = null
      this.dragItemId = null
    },
    async speechAllTasks () {
      let audioElement = null
      const speechText = `Ваши текущие задачи: ${this.filteredTasks.new?.map(
        (item) => item.title
      )}`

      const response = await postTextToSpeech(speechText, 'ru')
      const url = `${process.env.VUE_APP_SERVER_URL}/${response.data.text}`
      audioElement = new Audio(url)
      audioElement.play()

      await new Promise((resolve) => setTimeout(resolve, 0))

      this.$refs.speechBtn.addEventListener('click', () => {
        audioElement.pause()
      })
    },
    deadlineExpired (deadline) {
      const currentDateMs = new Date().getTime()
      const taskDateMs = new Date(deadline).getTime()
      if (currentDateMs > taskDateMs) return true
      return false
    },
    get_status (status) {
      switch (status) {
        case 'new': return 'Новая'
        case 'working': return 'В процессе'
        case 'completed': return 'Готово'
        case 'accepted': return 'Проверено'
        default: return status
      }
    },

    changeActiveButton (idx, active) {
      if (this.screenWidth < 880) {
        this.activeButton1 = false
        this.activeButton2 = false
        this.activeButton3 = false
        this.activeButton4 = false
        if(idx === 1) this.activeButton1 = true
        else if(idx === 2) this.activeButton2 = true
        else if(idx === 3) this.activeButton3 = true
        else if(idx === 4) this.activeButton4 = true
        return false
      }
      let activeButtons = 0
      const changeActive = () => {
        if (idx === 1) this.activeButton1 = !this.activeButton1
        else if (idx === 2) this.activeButton2 = !this.activeButton2
        else if (idx === 3) this.activeButton3 = !this.activeButton3
        else if (idx === 4) this.activeButton4 = !this.activeButton4
      }

      if (this.activeButton1) activeButtons++
      if (this.activeButton2) activeButtons++
      if (this.activeButton3) activeButtons++
      if (this.activeButton4) activeButtons++

      if (active) {
        changeActive()
        return false
      }
      if (activeButtons < this.countColumn) changeActive()
      else return false
    },
    handleResize: function () {
      this.screenWidth = window.innerWidth
      if (this.screenWidth > 1880) {
        this.countColumn = 4
        this.activeButton1 = true
        this.activeButton2 = true
        this.activeButton3 = true
        this.activeButton4 = true
      } else if (this.screenWidth > 1260) {
        this.countColumn = 3
        this.activeButton1 = true
        this.activeButton2 = true
        this.activeButton3 = true
        this.activeButton4 = false
      } else if (this.screenWidth > 880) {
        this.countColumn = 2
        this.activeButton1 = true
        this.activeButton2 = true
        this.activeButton3 = false
        this.activeButton4 = false
      } else {
        this.countColumn = 1
        this.activeButton1 = true
        this.activeButton2 = false
        this.activeButton3 = false
        this.activeButton4 = false
      }
    },

    changeShowDropdownCreator () {
      this.showDropdownCreator = !this.showDropdownCreator
    },
    changeShowDropdownExecutor () {
      this.showDropdownExecutor = !this.showDropdownExecutor
    },

    changeSelectedUserCreator(user) {
      this.showDropdownCreator = false
      this.selectedUserCreator = `${user.name} ${user.surname}`
      this.selectedUserCreatorId = user.id
    },
    changeSelectedUserExecutor(user) {
      this.showDropdownExecutor = false
      this.selectedUserExecutor = `${user.name} ${user.surname}`
      this.selectedUserExecutorId = user.id
    },
    resetCreator () {
      this.selectedUserCreator = ''
      this.selectedUserCreatorId = ''
      this.showDropdownCreator = false
    },
    resetExecutor () {
      this.selectedUserExecutor = ''
      this.selectedUserExecutorId = ''
      this.showDropdownExecutor = false
    },

    resetFilter () {
      this.resetCreator()
      this.resetExecutor()
      this.filterType = 'me_participant'
      this.filterStatus = 'all'
      this.searchValue = ''
      this.fetchTasks()
    }
  },
  beforeRouteLeave (to, from, next) {
    this.voiceEnable = false
    clearInterval(this.interval)
    next() 
  },

  beforeUnmount () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap");

p {
  color: white;
}

h2 {
  color: white;
  font-family: "Mulish";
  margin-top: 10px;
  margin-left: 30px;
  font-size: 30px;
}

.workspace_filter_bar {
  position: relative;
  width: 89%;
  top: 50px;
  left: 150px;
  border-bottom: 1px solid #ecc22c;
}

.admin_checkbox {
  display: flex;
}

.workspace_search_tasks {
  position: relative;
  width: 246px;
  height: 38px;
  left: 5px;
  top: -30px;
  background: #d9d9d9;
  border: none;
  border-radius: 5px;
}

.workspace_sort_task {
  position: relative;
  width: 196px;
  height: 38px;
  left: 5px;
  margin-left: 5px;
  top: -29px;
  border: none;
  background: #ecc22c;
  background-image: url("@/assets/images/Workspace/Sort.png");
  background-repeat: no-repeat;
  background-position: left 5px center;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Mulish";
}

.workspace_filter_task {
  position: relative;
  width: 196px;
  height: 38px;
  left: 5px;
  margin-left: 5px;
  top: -29px;
  border: none;
  background: #ecc22c;
  background-image: url("@/assets/images/Workspace/Filter.png");
  background-repeat: no-repeat;
  background-position: left 5px center;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Mulish";
}

.workspace_filter_task.speech {
  background-image: none !important;
}

.filter_option {
  text-align: center;
  font-family: "Mulish";
}

.create_task_btn {
  position: relative;
  width: 44px;
  height: 38px;
  left: 10px;
  top: -15.5px;
  border: none;
  background: #ecc22c;
  background-image: url("@/assets/images/Workspace/CreateTask.png");
  background-position: left 12px center;
  background-repeat: no-repeat;
  border-radius: 5px;
  cursor: pointer;
}

.admin_checkbox {
  margin-left: 10px;
  margin-top: -10px;
}

.admin_checkbox label {
  margin-left: 5px;
  color: white;
  font-family: "Mulish";
}

.columns {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  left: 122px;
  margin-top: 100px;
  width: 100%;
  height: 100vh;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

.columns::-webkit-scrollbar {
  margin-right: 100px;
  width: 8px;
  height: 6px;
}

.columns::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.columns::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.columns::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.column {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  margin-left: 29px;
  min-width: 318px;
  margin-top: 10px;
  border-top: 1px solid #ecc22c;
}

.card_img {
  border: 1px solid rgba(255, 255, 255, 0.082);
  border-radius: 70px;
}

.card_end {
  background: green;
}

.deadline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 35px;
  left: -90px;
  width: 112px;
  height: 21px;
  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: #ecc22c;
  border-radius: 5px;
}

.deadline h6 {
  font-size: 12px;
  font-family: "Mulish";
}

.card {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  margin-top: 19px;
  width: 318px;
  height: 118px;
  background: #343436;
  border-radius: 5px;
  transition: transform 0.3s ease-out;
}

.card_negative {
  background: rgb(70, 2, 2);
}

.class_positive {
  background: rgba(0, 128, 0, 0.412);
}

.card_done {
  background: green;
}

.card_working {
  background: rgba(255, 255, 0, 0.402);
}

.green_completed {
  background: green;
}

.card_header {
  width: 95%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
}

.card_buttons {
  z-index: 5;
}

.task_title {
  display: flex;
  width: 100%;
  margin-left: 20px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
}

.left_part {
  display: flex;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #d9d9d9;
}

.left_part p {
  position: relative;
  left: 5px;
  top: 10px;
}

.card:hover {
  transform: translate(0, -10px);
}

.slide-enter-active {
  opacity: 0;
  transform: translateX(100px);
}

.slide-fade-move {
  transition: transform 0.3s, opacity 0.3s;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
  opacity: 0;
  transform: translateY(20px);
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
  opacity: 0;
  transform: translateY(-20px);
}

.slide-fade-enter-to {
  opacity: 1;
  transform: translateY(0);
  animation: ani forwards;
}

.slide-fade-leave-to {
  animation: ani forwards;
  opacity: 0;
  transform: translateY(-20px);
}

.slide-enter-to {
  position: absolute;
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.slide-leave-to {
  position: absolute;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
  opacity: 0;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
  opacity: 1;
}

.slide-fade-enter-to {
  opacity: 1;
  animation: ani forwards;
}

.slide-fade-leave-to {
  animation: ani forwards;
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loading {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}

.error {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 19px;
  color: #ffffff;
}

.loading:before {
  content: "";
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: loading 1.2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.workspace {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  align-items: start;
  padding: 0 16px 16px 32px;
}
.workspace-layout {
  margin-bottom: 12px;
}
.workspace-layout-btn {
  border: none;
  border-radius: 8px;
  margin-right: 4px;
  padding: 4px 8px;
}
.workspace-layout-btn-active {
  background-color: lightgray;
}

.workspace-content {
  display: flex;
}
.workspace-content-column {
  min-width: 368px;
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 8px 16px;
  margin-right: 12px;
}
.workspace-content-disable {
  display: block;
}

.workspace-content-column h3 {
  color: #949494;
  font-size: 14px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
}

.workspace-content-column-el {
  position: relative;
  width: 336px;
  display: flex;
  flex-direction: column;
  background-color: #FDFDFD;
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 12px;
  opacity: 1;
}
.workspace-content-column-el h4 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}
.workspace-content-column-el div {
  color: #5190D9;
  font-size: 16px;
  font-weight: 400;
}
.workspace-content-column-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.workspace-content-column-flex img {
  width: 20px;
  height: 20px;
}

.workspace-header-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #949494;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
}

.workspace-header-search-flex {
  display: flex;
  flex-wrap: wrap;
}

.workspace-header-search input {
  width: 228px;
  border: none;
  border-bottom: 1px solid #EDEDED;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  padding: 10px;
  margin-right: 16px;
}

@media screen and (max-width: 1024px) {
  .workspace-header-search h3 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .workspace-content {
    padding-bottom: 72px;
  }

  .workspace-header-search-flex select {
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .workspace {
    padding: 8px;
  }
  .workspace-content {
    width: 100%;
  }
  .workspace-content-column {
    min-width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 8px 16px;
    margin-right: 12px;
  }
  .workspace-content-column-el {
    width: 100%;
    border-radius: 12px;
    padding: 12px 16px;
    margin-bottom: 12px;
  }
}

</style>
