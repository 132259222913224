import axios from 'axios'
import router from '@/router'
import { getUserActive } from '@/api/get'
import { cookies, Header } from '@/api/index'

// Appeal
export const postAddAppeal = (data) => {
  const appeal = {
    client_id: data.client_id,
    company: data.company,
    object: data.object,
    info: data.info,
    price: data.price,
    city: data.city,
    street: data.street,
    apart: data.apart,
    type: data.type
  }

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}appeals/`, appeal, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание обращения):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

export const refreshTocken = () => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}users/refresh?token=${cookies.get('REFRESH_JWT')}`)
    .then((response) => {
      cookies.set('REFRESH_JWT', response.data.refresh_token)
      cookies.set('JWT', response.data.access_token, '2h')
      router.go(0)
    })
    .catch(() => {
      console.log('Ошибка обновления токена')
    })
}

// Departament
export const postAddDepartament = (data) => {
  const departament = {
    director_id: data.director_id,
    assistant_id: data.assistant_id,
    title: data.title,
    text: data.text
  }

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}departaments/`, departament, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание отдела):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

// Direction
export const postAddDirection = (data) => {
  const direction = { title: data.title }

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}directions/`, direction, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание направления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

// Employee
export const postAddEmployee = (data) => {
  const employee = {
    user_id: data.user_id,
    departament_id: data.departament_id
  }

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}employees/`, employee, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание сотрудника):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

// Estime
export const postAddEstime = (data) => {
  const estime = {
    client_id: data.client_id,
    appeal_id: data.appeal_id,
    positive: data.positive,
    negative: data.negative,
    score: data.score
  }

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}estimates/`, estime, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание оценки):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

// Role
export const postAddRole = (data) => {
  const role = { title: data.title }

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}roles/`, role, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание роли):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

// User
export const signIn = async (data) => {
  const user = {
    username: data.username,
    password: data.password
  }

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}users/signin`, user, { headers: Header.login })
    .then((response) => {
      if (response.data.access_token) {
        cookies.set('REFRESH_JWT', response.data.refresh_token)
        cookies.set('JWT', response.data.access_token, '2h')
        router.go(0)
      }
    })
    .catch((error) => {
      console.log(`Ошибка POST запроса (авторизация):
      1)ответ: ${error.response ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response ? error.response.status : 'не определен'}.`)
      alert('Неверный логин или пароль')
    })
}

export const register = (data) => {
  const user = {
    name: data.name,
    surname: data.surname,
    patronymic: data.patronymic,
    phone_number: data.phone_number,
    email: data.email,
    username: data.username,
    password: data.password
  }
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}users/signup`, user)
    .then((response) => {
      if (response.data.access_token) {
        cookies.set('REFRESH_JWT', response.data.refresh_token)
        cookies.set('JWT', response.data.access_token, '2h')
        router.go(0)
      }
    })
    .catch((error) => {
      console.log(`Ошибка POST запроса (регистрация):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка регистрации. Введены неккоректные данные')
    })
}

export const postAddUser = (data) => {
  const user = {
    username: data.username,
    password: data.password,
    name: data.name,
    surname: data.surname,
    patronymic: data.patronymic,
    phone_number: data.phone_number,
    email: data.email
  }

  if (data.telegram_id) user.telegram_id = data.telegram_id
  if (data.role_id) {
    user.role_id = data.role_id
    user.is_verified = data.is_verified
    user.is_superuser = data.is_superuser
    user.is_client = data.is_client
    user.is_stuff = data.is_stuff
  }
  if (data.direction_id) user.direction_id = data.direction_id

  getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}users/signup`, user, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

export const postPhotoUser = (id, file) => {
  return axios.post(`${process.env.VUE_APP_SERVER_URL}users/${id}/image`, file, { headers: Header.mult })
    .then((response) => response)
    .catch((error) => console.log('ERROR POST add new photo user:', error.toJSON()))
}
export const postAdminPhoto = (file) => {
  return axios.post(`${process.env.VUE_APP_SERVER_URL}users/image`, file, { headers: Header.mult })
    .then((response) => response)
    .catch((error) => console.log('ERROR POST add new photo user:', error.toJSON()))
}

// export const postEvent = (data) => {
//   console.log('post', data)
//   return axios.post(`${process.env.VUE_APP_SERVER_URL}events/?title=${data.title}&text=${data.text}`, data.image, { headers: Header.mult })
//     .then((response) => response)
//     .catch((error) => console.log('ERROR POST add new event:', error.toJSON()))
// }

export const postTask = (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}tasks/`, data, { headers: Header.json })
    .catch((error) => console.log('ERROR POST add task', error.toJSON()))
}

export const postTime = () => {
  const user = {
    user_id: localStorage.getItem('ID')
  }

  return axios.post(`${process.env.VUE_APP_SERVER_URL}work_time/`, user)
    .then((response) => response)
    .catch((error) => console.log('ERROR POST add time user:', error.toJSON()))
}

export const postRegisterAppeal = (data) => {
  const appeal = {
    client_id: data.client_id,
    company: data.company,
    object: data.object,
    info: data.info,
    price: data.price,
    city: data.city,
    street: data.street,
    apart: data.apart,
    type: data.type
  }

  return axios.post(`${process.env.VUE_APP_SERVER_URL}appeals/`, appeal, { headers: Header.json })
    .then((response) => response)
    .catch((error) => console.log('ERROR POST add appeal', error.toJSON()))
}

// Создание отчёта по заданиям
export const postAnalytic = (object) => {
  const analytic = {
    from_date: object.from_date,
    to_date: object.to_date,
    list_users: object.list_users
  }

  if (!analytic.from_date) delete analytic.from_date
  if (!analytic.to_date) delete analytic.to_date
  if (!analytic.list_users) delete analytic.list_users

  return axios.post(`${process.env.VUE_APP_SERVER_URL}analytic/tasks/excel/download`, analytic, { responseType: 'blob', headers: Header.json })
    .then((response) => response)
    .catch((error) => console.log('ERROR POST get file', error.toJSON()))
}

// создать отчет
export const postCreateReport = (object) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}reports/`, { title: object.title, text: object.text }, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

export const postCreateChat = (title, orgId) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}chats/`, { title: title, organization_id: orgId }, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

export const postAddUserInChat = (chatId, userId) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}chat_members/`, { user_id: userId, chat_id: chatId }, { headers: Header.json })
    .then(() => true)
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

// озвучить текст
export const postTextToSpeech = (text, language) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}tts/`, { text: text, language: language }, { headers: Header.json })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка создания. Введены неккоректные данные.')
    })
}

export const postAssistantQuestion = (question) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}assistant/find`, { question: question }, { headers: Header.auth })
    .then((response) => {
      if (response) {
        return response
      }
    })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение списка пользователей):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const postVoiceResponse = (text) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL_CHAT}/gpt_service/sentiment_checker`, { text }, { headers: Header.json })
    .then((response) => {
      if (response) {
        return response
      }
    })
    .catch((error) => {
      console.error('Ошибка при отправке POST-запроса:', error)
      throw error
    })
}

// export const postEvent = (data) => {
//   return axios.post(`${process.env.VUE_APP_SERVER_URL}events/`, data, { headers: Header.mult })
//     .then((response) => response)
//     .catch((error) => console.log('ERROR POST add new event:', error.toJSON()))
// }

export const getAllTasks = async (creatorId, orgId) => {
  const task = {
    organization_id: orgId,
    creator_id: creatorId,
    is_blocked: false
  }
  // getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter?creator_id/`, ['new', 'working', 'accepted', 'completed'], { params: task, headers: Header.auth })
    .then((response) => { if (response) return response })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение задач пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getCreaterTasks = (orgId, executorId) => {
  // .post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter/?organization_id=${orgId}&executor_id=${executorId}`, { headers: Header.auth })

  // const task = {
  //   organization_id: orgId,
  //   executor_id: executorId
  // }

  return axios
    .get(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter/?organization_id=${orgId}&executor_id=${executorId}`, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение задач пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getUserTasks = async (userId, orgId) => {
  const task = {
    organization_id: orgId,
    executor_id: userId,
    is_blocked: false
  }

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter?executor_id/`, ['new', 'working', 'accepted', 'completed'], { params: task, headers: Header.auth })
    .then((response) => { if (response) return response })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение задач пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getCreatorTasks = async (creatorId, orgId) => {
  const task = {
    organization_id: orgId,
    creator_id: creatorId,
    is_blocked: false
  }
  // getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter?creator_id/`, ['new', 'working', 'accepted', 'completed'], { params: task, headers: Header.auth })
    .then((response) => { if (response) return response })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение задач пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const getSpectatorTasks = async (spectatorId, orgId) => {
  const task = {
    organization_id: orgId,
    spectator_id: spectatorId,
    is_blocked: false
  }
  // getUserActive()

  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}tasks/get_all_with_or_filter?spectator_id/`, ['new', 'working', 'accepted', 'completed'], { params: task, headers: Header.auth })
    .then((response) => { if (response) return response })
    .catch((error) => {
      console.log(`Ошибка GET запроса (получение задач пользователя):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
    })
}

export const createOrganization = async (data) => {
  const payload = {
    title: data.title,
    INN: data.inn,
    address: data.address,
    number: data.number,
    convener_id: data.convener_id
  }
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}organizations/`, payload, { headers: Header.auth })
    .catch((error) => {
      console.log('err', error)
    })
}

export const requestJoinOrganization = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}organization_members/`, { user_id: data.userId, organization_id: data.organizationId, status: 'WAITING' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (запрос на вступление в организацию):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на вступление в организацию. Введены неккоректные данные.')
    })
}

export const NotificationNewTask = async (userId, text) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}notifications/`, { user_id: userId, text: text, type: 'task' }, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание уведомления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на создание уведомления.')
    })
}

export const createAccounts = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}accounts/`, data, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание уведомления):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на создание уведомления.')
    })
}

export const createTaskType = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}task_types/`, data, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание типа задачи):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка отправки запроса на создание уведомления.')
    })
}

export const createTaskComment = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}comments/`, data, { headers: Header.auth })
    .catch((error) => {
      console.log(`Ошибка POST запроса (создание комментария для задачи):
      1)ответ: ${error.response.data.detail ? error.response.data.detail : 'Ошибка запроса'}
      2)код: ${error.response.status ? error.response.status : 'не определен'}.`)
      if (error.response.status === 400) alert(error.response.data.detail)
      else alert('Ошибка POST запроса (создание комментария для задачи')
    })
}

export const createMinitask = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}mini_tasks/`, data, { headers: Header.auth })
    .catch((error) => {
      console.log('Ошибка POST запроса (создание подзадачи для задачи)', error)
    })
}

// TimesheetWorkers

export const createTimesheetData = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}timesheet_service/work_days/`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

export const tableAddNotAuthUser = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}timesheet_workers/`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

export const createTransaction = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}check_receipt/receipts/`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

export const changeTopUpAccount = async (data, orgId) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}organizations/${orgId}/financial_transactions`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

// https://testcrm.ananas.guru/api/organizations/{organization_id}/financial_transactions

export const createFinancialTransactions = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}users/me/create_financial_transaction`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

export const transactionRespond = async (data) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}users/me/financial_transactions/transaction_respond`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

export const transactionFromOrganization = async (data, orgId) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}organizations/${orgId}/withdraw_funds`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

export const transactionsTopUpOrganization = async (data, orgId) => {
  return axios
    .post(`${process.env.VUE_APP_SERVER_URL}organizations/${orgId}/add_funds`, data, { headers: Header.auth })
    .catch(() => {
      console.log('Ошибка POST запроса')
    })
}

// https://testcrm.ananas.guru/api/organizations/68b3955d-e09d-4c40-8d40-c04b101ad3ba/withdraw_funds
