export const validUsername = (value) => {
  if (!value) return 'Введите login'
  const regex = /^[0-9A-Za-z]{2,20}$/
  if (!regex.test(value)) return 'Разрешены только цифры и буквы латиницы. Длина от 2 до 20 символов Без спец символов'
  return ''
}

export const validPassword = (value) => {
  const HAS_LETTER = /[a-zA-Z]/
  const HAS_DIGIT = /\d/
  const HAS_MIN_LENGTH = /.{6,}/
  const NO_SPACES = /^[^\s]+$/
  const NO_RUSSIAN_LETTERS = /^(?!.*[\u0400-\u04FF]).*$/

  if (!value) return 'Введите пароль'
  else if (!HAS_LETTER.test(value)) return 'Пароль должен содержать хотя бы одну латинскую букву'
  else if (!HAS_DIGIT.test(value)) return 'Пароль должен содержать хотя бы одну цифру'
  else if (!NO_RUSSIAN_LETTERS.test(value)) return 'Пароль не должен содержать кирилицу'
  else if (!NO_SPACES.test(value)) return 'Пароль не должен содержать пробелы'
  else if (!HAS_MIN_LENGTH.test(value)) return 'Пароль должен содержать не менее 6 символов'
  return ''
}

// export const validPassword = (value) => {
//   const HAS_LOWERCASE_LETTER = /[a-z]/
//   const HAS_UPPERCASE_LETTER = /[A-Z]/
//   const HAS_DIGIT = /\d/
//   const HAS_SPECIAL_CHAR = /[@$!%*?&]/
//   const HAS_MIN_LENGTH = /.{10,}/
//   const DONT_HAS_RUS = /^[^а-яёА-ЯЁ]*$/

//   // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
//   if (!value) return 'Введите пароль'
//   else if (!HAS_LOWERCASE_LETTER.test(value)) return 'Пароль должен содержать хотя бы одну строчную букву'
//   else if (!HAS_UPPERCASE_LETTER.test(value)) return 'Пароль должен содержать хотя бы одну заглавную букву'
//   else if (!HAS_DIGIT.test(value)) return 'Пароль должен содержать хотя бы одну цифру'
//   else if (!HAS_SPECIAL_CHAR.test(value)) return 'Пароль должен содержать хотя бы один специальный символ из набора @$!%*?&'
//   else if (!DONT_HAS_RUS.test(value)) return 'Пароль не должен содержать кирилицу'
//   else if (!HAS_MIN_LENGTH.test(value)) return 'Пароль должен содержать не менее 10 символов'
//   return ''
// }

export const validName = (value) => {
  if (!value) return 'Введите имя'
  // const regex = /^[А-ЯЁ][а-яё]*([-][А-ЯЁ][а-яё]*)*$/g
  const regexCyrillic = /^[а-яёА-ЯЁ]+$/
  const regexCapitalLetter = /^[А-ЯЁ][а-яё]*$/
  if (!regexCyrillic.test(value)) return "Формат: 'Имя' с использованием только кириллицы"
  else if (!regexCapitalLetter.test(value)) return 'Имя не должно содержать загланых букв'
  return ''
}

export const validSurname = (value) => {
  if (!value) return 'Введите фамилию'
  const regexCyrillic = /^[а-яёА-ЯЁ]+$/
  const regexCapitalLetter = /^[А-ЯЁ][а-яё]*$/
  if (!regexCyrillic.test(value)) return "Формат: 'Фамилия' с использованием только кириллицы"
  else if (!regexCapitalLetter.test(value)) return 'Фамилия не должно содержать загланых букв'
  return ''
}

export const validPatronymic = (value) => {
  if (!value) return 'Введите отчество'
  const regexCyrillic = /^[а-яёА-ЯЁ]+$/
  const regexCapitalLetter = /^[А-ЯЁ][а-яё]*$/
  if (!regexCyrillic.test(value)) return "Формат: 'Отчество' с использованием только кириллицы"
  else if (!regexCapitalLetter.test(value)) return 'Отчество не должно содержать загланых букв'
  return ''
}

export const validNumber = (value) => {
  if (!value) return 'Введите номер телефона'
  if (value.length < 11) return 'Номер телефона слишком короткий' // Проверка на минимальное количество символов
  const regex = /^(\s*)?(\+?7)?9\d{9}(\s*)?$/ // Измененное регулярное выражение
  if (!regex.test(value)) return "Формат: '+7' и далее 10 цифр, начиная с '9'"
  return ''
}

export const validOrganizationNumber = (value) => {
  if (!value) return 'Введите номер телефона'
  if (String(value)[0] !== '8') return 'Номер должен начинаться с 8'
  if (value.length !== 11) return 'Номер телефона должен содержать 11 цифр'
  // const regex = /^(\s*)?(\+?7)?9\d{9}(\s*)?$/ // Измененное регулярное выражение
  // if (!regex.test(value)) return "Формат: '+7' и далее 10 цифр, начиная с '9'"
  return ''
}

// export const validEmail = (value) => {
//   if (!value) return 'Введите email'
//   const regex = /^[0-9A-Za-z.!]+@[A-Za-z]{3,}\.[A-Za-z]{2,}$/ // Регулярное выражение с учетом условий
//   if (!regex.test(value)) return 'Неправильный формат email Пример правильного формата your@login.com'
//   return ''
// }
export const validEmail = (value) => {
  if (!value) return 'Введите email'
  const regex = /^[0-9A-Za-z.!_-]+@[A-Za-z]{3,}\.[A-Za-z]{2,}$/ // Измененное регулярное выражение
  if (!regex.test(value)) return 'Неправильный формат email. Пример правильного формата: your@login.com'
  return ''
}

export const validTelegram = (value) => {
  const regex = /^[0-9]/g
  if (!value) return ''
  else if (!regex.test(value)) return 'Разрешены только цифры.'
}

export const validTitle = (value) => {
  if (!value) return 'Поле не может быть пустым'
  return ''
}

export const validText = (value) => {
  if (!value) return 'Поле не может быть пустым'
  return ''
}

export const validCity = (value) => {
  if (!value) return 'Введите город'
  const regex = /^[А-Яа-яЁёA-Za-z]/g
  if (!regex.test(value)) return 'Разрешено использование только кириллицы'
  return ''
}

export const validStreet = (value) => {
  if (!value) return 'Введите улицу'
  const regex = /^[А-Яа-яЁёA-Za-z0-9]/g
  if (!regex.test(value)) return 'Разрешено использование только букв и цифр'
  return ''
}

export const validApart = (value) => {
  if (!value) return 'Введите дом'
  const regex = /^[А-Яа-яЁёA-Za-z0-9]/g
  if (!regex.test(value)) return 'Разрешено использование только букв и цифр'
  return ''
}

export const validCompany = (value) => {
  if (!value) return 'Введите компанию'
  const regex = /^[А-Яа-яЁёA-Za-z0-9]/g
  if (!regex.test(value)) return 'Разрешено использование только букв и цифр'
  return ''
}

export const validObejct = (value) => {
  if (!value) return 'Поле не может быть пустым'
  return ''
}

export const validInfo = (value) => {
  if (!value) return 'Поле не может быть пустым'
  return ''
}

export const validInputText = (value) => {
  if (!value) return 'Введите текст'
  const regex = /^[0-9A-Za-z.!?@]{5,100}/g
  if (!regex.test(value)) return 'Разрешены только цифры, буквы латиницы и знаки \\".!?@\\". Длина от 5 до 100 символов.'
  return ''
}

export const validOrganization = (value) => {
  if (!value) return 'Введите текст'
  return ''
}

export const validInn = (value) => {
  if (!value) return 'Введите текст'
  else if (value.length !== 10) return 'Длина инн должна быть равна 10 цифрам'
  // else if (value[0]) return 'Инн не может начинаться с 0'
  return ''
}

export const validAccountTitle = (value) => {
  if (!value) return 'Введите название'
  const regex = /^[А-Яа-яЁёA-Za-z0-9]/g
  if (!regex.test(value)) return 'Разрешено использование только букв и цифр'
  return ''
}

export const validAccountDebit = (value) => {
  if (!value) return 'Выберите дебит'
  return ''
}

export const validAccountCredit = (value) => {
  if (!value) return 'Выберите кредит'
  return ''
}

export const validAccountAmount = (value) => {
  if (!value) return 'Введите стоимость'
  return ''
}
